.blog-card-container {
  .blog-mini-post {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
    }

    .image {
      img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    .tags {
      margin-top: 10px;

      .tag {
        display: inline-block;
        background-color: #007bff;
        color: #ffffff;
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 0.75em;
      }
    }
  }
}
